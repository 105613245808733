@import 'mixins';

@mixin link-button {
  visibility: visible;
  background: $kittch-white-color;
  @include font-text__small--heavy;
  height: 60px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $kittch-black-color !important;
  border: 2px solid $kittch-black-color;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  width: 100%;
}

app-recipe-card,
app-main-stage-stream,
app-main-stage-channel {
  .link {
    @include link-button;
  }
}

app-recipe-card .recipe-card__action-button,
app-main-stage-stream .top-stream__action-button,
app-main-stage-channel .top-channel__action-button {
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    box-shadow: 4px 4px 0 $kittch-black-color;
    transition: 0.2s ease;
  }

  &:active:not([disabled]) {
    box-shadow: inset 4px 4px 0 $kittch-black-color;
    transition: 0.2s ease;
  }
}
