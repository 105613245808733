/* You can add global styles to this file, and also import other style files */

@import './libs/ui/src/styles';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// MODAL SDK
.modal-sdk-backdrop {
  background-color: rgb(10 10 10 / 86%);
}

.modal-sdk {
  // for top icon, which can be over of white bg
  --mdc-dialog-container-color: transparent;
}
