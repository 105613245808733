@import 'mixins';

.select-wrap {
  position: relative;
  overflow: hidden;
  width: 540px;
  background: $kittch-white-color;
}

.select-wrap__select {
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
  @include font-text__medium--regular;
  color: $kittch-black-color;
  border: 1px solid $kittch-medium-grey-color;
  border-radius: 4px;
  padding: 11px 16px;
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjg1NzE2NCAwLjVDMC41MTA0ODQgMC41IDAuMTk3OTQgMC43MTMxODcgMC4wNjUyNzA5IDEuMDQwMTVDLTAuMDY3Mzk4IDEuMzY3MTIgMC4wMDU5MzUyMSAxLjc0MzQ3IDAuMjUxMDc1IDEuOTkzNzJMNS4zOTM5MSA3LjI0MzcyQzUuNzI4NjQgNy41ODU0MyA2LjI3MTM1IDcuNTg1NDMgNi42MDYwOSA3LjI0MzcyTDExLjc0ODkgMS45OTM3MkMxMS45OTQxIDEuNzQzNDcgMTIuMDY3NCAxLjM2NzEyIDExLjkzNDcgMS4wNDAxNUMxMS44MDIxIDAuNzEzMTg3IDExLjQ4OTUgMC41IDExLjE0MjggMC41SDAuODU3MTY0WiIgZmlsbD0iIzEyMTIxMCIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: right 20px top 50%;
  background-size: 12px 7px;
}

.select-wrap__select.ng-pristine {
  color: $kittch-grey-color;
}

.select-wrap__select:not(.ng-pristine) {
  color: $kittch-black-color;
}
