@import 'mixins';

.empty-list {
  margin: 160px auto;
  max-width: 446px;

  &--part {
    margin: 16px auto;
  }

  &__title {
    @include font-title__small--heavy--supreme-family;
    text-align: center;
    color: $kittch-grey-color;
  }

  &__text {
    @include font-text__large--regular;
    text-align: center;
    color: #acacac;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0;
    &--streaming-now {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
