@import 'mixins';

.edit-profile {
  textarea {
    resize: vertical;
  }

  .header {
    position: relative;
    &__title h1 {
      @include font-h1;
      letter-spacing: 0;
      color: $kittch-black-color;
      margin: 0 0 40px 0;
    }
    &__buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: 165px;
      margin: -8px 0;
      app-button {
        margin: 8px 0;
      }
    }
    app-avatar .info-details {
      max-width: 440px;
    }
  }
  .form {
    max-width: 846px;
    width: 100%;
    h2 {
      @include font-title__x-small--heavy--supreme-family;
      letter-spacing: 0;
      margin-bottom: 24px;
      color: $kittch-black-color;
    }
    &__information {
      h2 {
        @include font-text__large--regular;
        letter-spacing: 0;
        margin-bottom: 22px;
        text-transform: capitalize;
        width: 100%;
      }
      &__description {
        @include font-text__medium--regular;
        color: $kittch-grey-color;
        display: block;
        text-transform: none;
        letter-spacing: 0;
      }
      .form {
        &-items {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -12px -12px -12px;
        }
        &__item {
          width: 270px;
          max-width: 270px;
          min-width: 270px;
          padding: 0 12px;
        }
      }
    }

    .form-section {
      .form-section__title {
        @include font-text__medium--heavy--supreme-family;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $kittch-black-color;
        margin-bottom: 6px;
      }

      .form-section__text {
        @include font-text__medium--regular;
        text-transform: initial;
        color: $kittch-black-color;
      }

      .form-section__text--optional {
        margin-left: 6px;
      }

      .form-section__text--subtitle {
        margin-bottom: 10px;
      }

      .form-section__text--input-title {
        margin-bottom: 8px;
        line-height: 16px;
      }

      .form-section__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px 0 -12px;
      }

      .form-section__input-wrap {
        position: relative;
        margin-bottom: 22px;
        padding: 0 12px;
        width: 258px;
      }
    }

    &__item {
      margin: 0 0 22px 0;
      &.select-type {
        margin: 0 0 16px 0;
      }
      h3 {
        @include font-text__medium--extra-heavy--supreme-family;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $kittch-black-color;
        margin-bottom: 24px;
        &.select-title {
          margin-bottom: 16px;
        }
      }
      &--upload {
        &__description {
          @include font-text__medium--regular;
          line-height: 22px;
          text-align: left;
          color: $kittch-grey-color;
          display: block;
          text-transform: none;
          padding-top: 8px;
        }
      }
      &__description {
        @include font-text__medium--regular;
        letter-spacing: 0;
        color: $kittch-black-color;
        margin-bottom: 16px;
      }
    }
    &__field--photo {
      margin-bottom: 32px;
    }
    &__delete-btn {
      background-color: $kittch-red-color;
      color: $kittch-white-color;
      height: 32px;
      max-height: 32px;
      padding: 8px 12px;
      text-transform: capitalize;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      transition: 0.2s ease;
      &:hover {
        box-shadow: 2px 2px 0 $kittch-black-color;
        transition: 0.2s ease;
      }
    }
    &-wrap {
      padding: 48px 56px 48px 40px;
      max-width: 1200px;
    }
    &-label {
      @include font-text__x-small--regular;
      letter-spacing: 0;
      margin-bottom: 6px;
      text-transform: capitalize;
      color: $kittch-black-color;
      display: block;
    }
    &-input {
      border: 1px solid $kittch-medium-grey-color;
      border-radius: 4px;
      width: 100%;
      padding: 12px 16px;
      @include font-text__medium--regular;
      letter-spacing: 0;
      color: $kittch-black-color;
      &::placeholder {
        color: $kittch-grey-color;
      }

    }
    input.form-input {
      height: 48px;
    }

    #story {
      min-height: 288px;
    }
  }

  @media all and (max-width: 1280px) {
    .header {
      app-avatar .info-details {
        max-width: 304px;
      }
    }
  }

  @media all and (max-width: 768px) {
    .header {
      &__title h1 {
        font-size: 32px;
        line-height: 36px;
      }
      app-button {
        height: 48px;
      }
      app-avatar .info-details {
        max-width: 205px;
      }
    }
    .form {
      h2 {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.02em;
      }
      textarea {
        line-height: 22px;
      }
      &__item {
        &__description {
          line-height: 22px;
          margin-bottom: 24px;
        }
        app-image-chooser {
          min-height: 48px;
          height: 100%;
          app-button {
            height: 48px;
          }
        }
      }
    }
  }

  @media all and (max-width: 615px) {
    .separator {
      margin: 24px 0;
    }
    .select-wrap {
      width: 100%;
    }
    .header {
      display: flex;
      flex-direction: column-reverse;
      &__title h1 {
        margin: 0 0 16px 0;
      }
      &__buttons {
        position: static;
        display: flex;
        width: 100%;
        margin: 0 -8px 16px -8px;
      }
      &__title h1 {
        font-size: 32px;
        line-height: 36px;
      }
      app-button {
        height: 48px;
        margin: 0;
        padding: 0 8px;
        width: 100%;
      }
    }
    .form {
      max-width: 100%;
      h2 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.04em;
      }
      &__information {
        .form {
          &__item {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
          }
        }
      }
      &__custom-buttons {
        .custom-buttons-wrap {
          display: flex;
          margin: 0px -12px 8px -12px;
          flex-direction: column;
        }
        .form-input-wrap {
          padding: 8px 12px;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }
      &-wrap {
        padding: 16px 16px 0 16px;
      }
      #story {
        min-height: 200px;
      }
      &__delete-btn {
        margin-bottom: 108px;
      }
    }
  }

  .form__field--publish-status {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  .status-label {
    padding-right: 10px;
  }
}

