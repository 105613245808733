@import 'libs/ui/src/lib/assets/stylesheets/variables';
@import 'libs/ui/src/lib/assets/stylesheets/mixins';

.form-wrap {
  padding: 30px 0;
}

.form__item {
  position: relative;
  margin: 0 0 40px 0;
}

.form__item--editor {
  margin-bottom: 40px;
}

.form__item--columns {
  display: flex;
  justify-content: space-between;
}

.form__item--center {
  display: flex;
  justify-content: center;
}

.form__item--left {
  display: flex;
  justify-content: flex-start;
}

.form__item--right {
  display: flex;
  justify-content: flex-end;
}

.form__title {
  text-align: center;
  margin-bottom: 50px !important;
}
.page__title {
  text-align: left;
}

.form__description {
  text-align: center;
  color: #767676;
}

.form__field {
  width: 100%;
  position: relative;
}

.form__field--half {
  width: 48%;
}
.form__field--quarter {
  width: 24%;
  margin: 0 0 0 20px;
}

.form__field--one-symbol {
  width: 48px;
}

.form__label {
  display: inline-block;
  font-size: 16px;
  padding-bottom: 5px;
}

.form__button {
  width: 100%;
}

.form__submit {
  max-width: 200px;
  width: 100%;
}

.form-error {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-error__text {
  font-family: Sofia Pro, sans-serif;
  margin: 3px 0 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $kittch-red-color;
}

.input--is-invalid {
  border-color: $kittch-red-color !important;
}

.entity-form {
  max-width: 846px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.entity-form-hidden {
  display: none;
}

.entity-form__slide-toggle-container {
  display: flex;
  align-items: center;
}

.entity-form__slide-toggle-container--margin-bottom {
  margin-bottom: 16px;
}

.entity-form__slide-toggle {
  margin-right: 24px;
}

.entity-form__slide-toggle-text {
  @include font-text__medium--extra-heavy--supreme-family;
  color: $kittch-black-color;
  text-transform: uppercase;
}

h3.entity-form__title {
  @include font-title__x-small--extra-heavy--supreme-family;
  text-transform: uppercase;
  color: $kittch-black-color;
  margin: 0;
}

h3.entity-form__title--margin-bottom {
  margin-bottom: 16px;
}

p.entity-form__subtitle {
  margin: 14px 0 20px 0;
  @include font-text__medium--regular;
  color: $kittch-black-color;
}

.entity-form__separator {
  height: 1px;
  max-width: 846px;
  margin: 0 0 32px 0;
  background: $kittch-dark-grey-color;
}

@media screen and (max-width: $smScreenWidth) {
  .entity-form {
    margin-bottom: 24px;
  }

  .entity-form__subtitle {
    margin-top: 12px;
  }

  .entity-form__slide-toggle {
    margin-right: 19px;
  }

  h3.entity-form__title {
    @include font-text__large--extra-heavy--supreme-family;
  }

  .entity-form__separator {
    width: 100%;
    margin: 0 0 24px 0;
  }
}
