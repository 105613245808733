@keyframes emoji {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes counter {
  0% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
  25% {
    transform: translate3d(0, 40%, 0);
    opacity: 0;
  }
  50% {
    transform: translate3d(0, -40%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
