$kittch-black-color: #121210;
$kittch-dark-grey-color: #636363;
$kittch-grey-color: #949494;
$kittch-medium-grey-color: #C2C2C2;
$kittch-light-grey-color: #E2E2E2;
$kittch-super-light-grey-color: #F2F2F2;
$kittch-white-color: #FFFFFF;
$kittch-rich-blue-color: #2575FF;
$kittch-blue-color: #52C5F2;
$kittch-green-color: #A6E6DB;
$kittch-blue-green-color: linear-gradient(90deg, $kittch-blue-color 0%, $kittch-green-color 100%);
$kittch-red-color: #FF4128;
$kittch-purple-color: #D38AFF;
$kittch-pink-color: #FDAEF8;
$black-color: #000000;
$menu-link-black-color: #2D2D2D;
$nick-black-color: #032619;
