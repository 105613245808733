.alert {
  color: $kittch-white-color;
  z-index: 100001;

  &--error {
    background-color: #f44336;
  }

  &--success {
    background-color: #46a35e;
  }
}

.cdk-overlay-container {
  z-index: 9999999;
}
