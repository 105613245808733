@import './../variables';

@mixin font($family, $weight, $size, $line-height) {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  font-style: normal;
}

@mixin title {
  text-transform: uppercase;
}

/* H1 font mixin */
/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 39px;
  line-height: 40px;
  text-transform: uppercase;
}*/
@mixin font-h1 {
  @include font($font-family-supreme, $font-weight-heavy, 39px, 40px);
  @include title;
}

/* TITLE MIXINS (font-size: 25-49px) */

/* Large(49px) title mixins */
/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 49px;
  line-height: 48px;
  text-transform: uppercase;
}*/
@mixin font-title__large--heavy {
  @include font($font-family-supreme, $font-weight-heavy, 49px, 48px);
  @include title;
}

/* Large(49px) title mixins */
/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 49px;
  line-height: 51px;
  text-transform: uppercase;
}*/
@mixin font-title__large--extra-heavy {
  @include font($font-family-supreme, $font-weight-extra-heavy, 49px, 51px);
  @include title;
}

/* Medium(39px) title mixins */
/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 39px;
  line-height: 40px;
  text-transform: uppercase;
}*/
@mixin font-title__medium--heavy {
  @include font($font-family-supreme, $font-weight-heavy, 39px, 40px);
  @include title;
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 39px;
  line-height: 110%;
  text-transform: uppercase;
}*/
@mixin font-title__medium--extra-heavy {
  @include font($font-family-supreme, $font-weight-extra-heavy, 39px, 110%);
  @include title;
}

/* Small(31px) title mixins */
@mixin font-title__small($family, $weight) {
  @include font($family, $weight, 31px, 32px);
  @include title;
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 31px;
  line-height: 32px;
  text-transform: uppercase;
}*/
@mixin font-title__small--heavy--supreme-family {
  @include font-title__small($font-family-supreme, $font-weight-heavy);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 31px;
  line-height: 32px;
  text-transform: uppercase;
}*/
@mixin font-title__small--extra-bold--supreme-family {
  @include font-title__small($font-family-supreme, $font-weight-extra-bold);
}

/* Extra Small (25px) title mixins */
@mixin font-title__x-small($family, $weight) {
  @include font($family, $weight, 25px, 28px);
  @include title;
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 25px;
  line-height: 28px;
  text-transform: uppercase;
}*/
@mixin font-title__x-small--heavy--supreme-family {
  @include font-title__x-small($font-family-supreme, $font-weight-heavy);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 25px;
  line-height: 28px;
  text-transform: uppercase;
}*/
@mixin font-title__x-small--extra-heavy--supreme-family {
  @include font-title__x-small($font-family-supreme, $font-weight-extra-heavy);
}
/* END TITLE MIXINS (font-size: 25-49px) */

/* TEXT MIXINS (font-size: 13-20px) */

/* Large (20px) text mixins */
@mixin font-text__large($family, $weight, $line-height) {
  @include font($family, $weight, 20px, $line-height);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}*/
@mixin font-text__large--regular {
  @include font-text__large($font-family-sofia-pro, $font-weight-regular, 32px);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
}*/
@mixin font-text__large--extra-bold {
  @include font-text__large($font-family-sofia-pro, $font-weight-extra-bold, 32px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}*/
@mixin font-text__large--heavy--supreme-family {
  @include font-text__large($font-family-supreme, $font-weight-heavy, 24px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 20px;
  line-height: 24px;
}*/
@mixin font-text__large--extra-heavy--supreme-family {
  @include font-text__large($font-family-supreme, $font-weight-extra-heavy, 24px);
}

/* Medium (16px) text mixins */
@mixin font-text__medium($family, $weight, $line-height) {
  @include font($family, $weight, 16px, $line-height);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}*/
@mixin font-text__medium--regular {
  @include font-text__medium($font-family-sofia-pro, $font-weight-regular, 24px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
}*/
@mixin font-text__medium--heavy--supreme-family {
  @include font-text__medium($font-family-supreme, $font-weight-heavy, 16px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 16px;
  line-height: 16px;
}*/
@mixin font-text__medium--extra-heavy--supreme-family {
  @include font-text__medium($font-family-supreme, $font-weight-extra-heavy, 16px);
}

/* Small (14px) text mixins */
@mixin font-text__small($weight) {
  @include font($font-family-supreme, $weight, 14px, 16px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}*/
@mixin font-text__small--heavy {
  @include font-text__small($font-weight-heavy);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 950;
  font-size: 14px;
  line-height: 16px;
}*/
@mixin font-text__small--extra-heavy {
  @include font-text__small($font-weight-extra-heavy);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
}*/
@mixin font-text__small--heavy--sofia-pro-family {
  @include font($font-family-sofia-pro, $font-weight-heavy, 14px, 24px);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}*/
@mixin font-text__small--medium {
  @include font($font-family-sofia-pro, 400, 14px, 18px);
}

/* Extra small (13px) text mixins */
@mixin font-text__x-small($family, $weight) {
  @include font($family, $weight, 13px, 16px);
}

/*{
  font-family: Supreme, sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
}*/
@mixin font-text__x-small--heavy--supreme-family {
  @include font-text__x-small($font-family-supreme, $font-weight-heavy);
}

/*{
  font-family: Sofia Pro, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}*/
@mixin font-text__x-small--regular {
  @include font-text__x-small($font-family-sofia-pro, $font-weight-regular);
}
/* END TEXT MIXINS (font-size: 13-20px) */

/*
  LIMIT THE HEIGHT OF THE TEXT BY GIVEN LINES AMOUNT
  PUT ... IN THE END.
  EXAMPLE:
  SOME VERY VERY VERY VERY VERY VERY VERY
  VERY VERY VERY VERY VERY VERY LONG TITL...

*/
@mixin limit-height-by-lines ($lines-amount) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines-amount;
  -webkit-box-orient: vertical;
  line-clamp: $lines-amount;
  box-orient: vertical;
  word-break: break-word;
}
