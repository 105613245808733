.search-input-wrap {
  max-width: 846px;
  padding: 16px;
  border: 1px solid $kittch-light-grey-color;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.search-input {
  @include font-text__medium--regular;
  height: 48px;
  width: 430px;
  padding: 12px 12px 12px 50px;
  border: 1px solid $kittch-medium-grey-color;
  border-radius: 4px;
  color: $kittch-grey-color;
  background: url('../images/svg/input-search.svg') no-repeat calc(0% + 17px);
}

@media screen and (max-width: $smScreenWidth) {
  .search-input-wrap {
    padding: 0;
    border: 0 solid $kittch-light-grey-color;
  }

  .search-input {
    height: 56px;
    width: 100%;
    border-top: 1px solid $kittch-light-grey-color;
    border-right: 1px solid $kittch-light-grey-color;
    border-bottom: unset;
    border-left: 1px solid $kittch-light-grey-color;
    border-radius: 4px 4px 0 0;
  }
}
