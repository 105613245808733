@import 'variables';

.checkmark {
  position: relative;
  display: flex;
}

.checkmark__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark__label {
  flex-grow: 1;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background: $kittch-white-color;
  border: 1px solid $kittch-medium-grey-color;
  border-radius: 4px;
  cursor: pointer;
}

.checkmark__input:checked + .checkmark__label {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='-2 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7L5.5 11L15 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  background-color: $kittch-black-color;
  border: none;
}

.checkmark__input:focus-visible + .checkmark__label {
  box-shadow: 0 0 1px 1px $kittch-black-color;
  border-color: #fff;
}

.checkmark__input:disabled {
  cursor: not-allowed;
}

.checkmark__input:disabled + .checkmark__label{
  cursor: not-allowed;
  background-color: $kittch-super-light-grey-color;
}
