@import 'mixins';
@import 'variables';

.modal-close-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.modal-close-btn__cross {
  position: absolute;
  width: 2px;
  height: 20px;
  left: 11px;
  top: 2px;
  background: $kittch-black-color;
  border-radius: 1px;
}

.modal-close-btn__cross--left {
  transform: rotate(45deg);
}

.modal-close-btn__cross--right {
  transform: rotate(135deg);
}

.top-flame-modal-icon {
  position: absolute;
  top: -50px;
  width: 104px;
  height: 110px;
}

.modal-title {
  @include font-title__medium--heavy;
  text-align: center;
  text-transform: uppercase;
  color: $kittch-black-color;
  margin-bottom: 24px;
}

.modal-description {
  max-width: 554px;
  @include font-text__large--regular;
  text-align: center;
  color: #000000;
  margin: auto;
}

.form-button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.withdraw-wrap {

}

.form-button {
  margin: 0 8px;

  &--subscribe {
    width: 140px;
  }

  &--unsubscribe {
    width: 142px;
  }

  &--buy {
    min-width: 97px;
  }

  &--cancel {
    width: 123px;
  }

  &--submit {
    width: 124px;
  }

  &--close {
    width: 111px;
  }

  &--withdraw {
    width: 155px;
  }
}

@media screen and (max-width: $tablet) {
  .modal-title {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .modal-description {
    max-width: 440px;
    font-size: 16px;
    line-height: 22px;
  }

  .form-button-wrap {
    margin-top: 32px;
  }

  .form-button {
    &--subscribe {
      width: 125px;
      height: 48px;
    }
  }
}

@media screen and (max-width: $smScreenWidth) {
  .top-flame-modal-icon {
    left: 125px;
    top: -37px;
  }

  .modal-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 22px;
  }

  .modal-description {
    max-width: 310px;
    font-size: 16px;
    line-height: 24px;
  }

  .form-button-wrap {
    margin-top: 24px;
  }
}
