// font family variables
$font-family-sofia-pro: Sofia Pro, sans-serif;
$font-family-supreme: Supreme, sans-serif;

// font weight variables
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;
$font-weight-extra-heavy: 950;
