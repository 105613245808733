.NgxEditor--custom {
  .NgxEditor__MenuBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .NgxEditor {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .NgxEditor__Content {
    height: 200px;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.04);
  }

  ol,
  ul {
    padding: 0 0 0 10px;
    margin: 10px;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  li {
    list-style-type: inherit;
  }
}
