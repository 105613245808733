.list-title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  h1 {
    margin: 0;
  }
}

.list-table {
  width: 100%;

  th,
  td {
    text-align: center !important;
  }

  .mat-column {
    &-id {
      width: 25%;
    }
    &-status {
      width: 10%;
    }
    &-actions {
      width: 20%;
    }
  }
}

.list-button {
  float: right;
}
