@import 'variables';

.sb-button {
  margin: 4px;
}

.sb-group {
  width: 100%;
  justify-content: center;
}

.sb-social-buttons .sb-wrapper {
  color: white;
  border-radius: 50%;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
  background-color: var(--button-color) !important;
  &.sb-show-icon.sb-show-text {
    .sb-text {
      filter: none;
      padding-right: 1em;
      padding-left: 0;
    }
  }
  .sb-icon {
    font-size: 32px;
    min-width: auto;
  }
}
