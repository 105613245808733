@import 'mixins';

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  display: inline-block;

  @include font-text__large--regular;
  line-height: unset;
  color: #212529;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: $kittch-white-color;

  border: 1px solid $kittch-medium-grey-color;
  box-sizing: border-box;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  position: absolute;
  border-radius: 100%;
  transition: all 0.2s ease;

  width: 14px;
  height: 14px;
  left: 2px;
  top: 50%;

  background: $kittch-black-color;
}

[type='radio']:checked + label:before {
  border: 1px solid $kittch-black-color;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1) translate(0, -50%);
}
