@import 'link-button';

.stream-page h1 {
  font-family: 'Sofia Pro', sans-serif;
}

.stream-page__featured-streams {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 -12px;
}

.stream-page__featured-streams app-stream-top-card {
  width: 33.33%;
  padding: 0 12px;
}

.stream-page__featured-streams .carousel app-stream-top-card {
  padding: 0;
}

.stream-page .stream-status {
  position: absolute;
  top: 16px;
  left: 8px;
}

.stream-page__container-home {
  padding: 0 0 72px 40px;
}

.stream-page__container-home--top {
  padding: 40px 58px 40px 40px;
}

.stream-page__container-home.stream-page__container-home--left {
  padding-left: 0;
}

.stream-page__container {
  padding: 0 56px 72px 40px;
}

.stream-page__container--top {
  max-width: 1104px;
  margin: 0 auto;
}

.stream-page__container--top,
.stream-page__container-home--top {
  padding: 40px 58px 40px 40px;
}

.stream-page__container h2,
.stream-page__container-home h2 {
  @include font-title__small--heavy--supreme-family;
  letter-spacing: 0;
  text-transform: uppercase;
  color: $kittch-black-color;
  margin: 0 0 32px 0;
}

.stream-page__container h2 a,
.stream-page__container-home h2 a {
  color: $kittch-black-color;
}

.stream-page__container h2 span,
.stream-page__container-home h2 span {
  cursor: pointer;
  margin-left: 16px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

.stream-page__container-top h2 {
  margin-top: 48px;
}

.stream-page__channel-info {
  display: flex;
  justify-content: space-between;
  padding: 24px 56px 24px 40px;
}

.stream-page__channel-info__profile {
  display: flex;
  align-items: center;
  width: calc(100% - 243px);
}

.stream-page__channel-info__profile__avatar {
  width: 100%;
}

.stream-page__channel-info__profile__description {
  width: 60%;
  padding: 0 39px 0 27px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.action-buttons {
  display: flex;
  width: 243px;
}

.stream-page__channel-info__add-view {
  width: 175px;
  min-width: 100px;
  margin-right: 8px;
}

.stream-page__channel-info__favorite {
  justify-content: center;
  display: flex;

}

.stream-page__action-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -12px;
}

.stream-page__action-button {
  width: 100%;
  max-width: 33.33%;
  padding: 0 12px;
}

.stream-page__action-button app-button {
  height: auto;
}

.stream-page__head {
  text-transform: uppercase;
}

.stream-page__head h2 {
  font-family: Supreme,Sofia Pro,sans-serif;
  font-size: 31px;
  line-height: 28px;
  font-weight: 900;
  margin-bottom: 40px;
  color: $kittch-black-color;
}

.stream-page__head span {
  @include font-text__small--heavy;
  font-family: 'Sofia Pro', sans-serif;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-left: 16px;
  cursor: pointer;
  color: #032619;
}

.stream-page__recipes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -12px;
}

// ----- SLIDER ----- //
.carousel-dots {
  bottom: -40px !important;
}

.carousel-dot {
  border: 2px solid $kittch-black-color !important;
  background: $kittch-black-color !important;
}

.carousel-dot-active {
  background-color: #fff !important;
}
// ----- _____ ----- //

.stream-page__container--swiper,
.stream-page__container--swiper {
  padding: 0 0 50px 40px;
}
// ----- _____ ----- //
@media all and (max-width: 1050px) {
  .stream-page__container--top {
    padding: 0;
  }
}


@media all and (max-width: 1024px) {
  .stream-page__container {
    padding: 0 24px 56px 24px;
  }

  .stream-page__container-home {
    padding: 0 0 56px 24px;
  }

  .stream-page__channel-info {
    flex-wrap: wrap;
    padding: 24px;
  }

  .stream-page__channel-info__profile {
    width: 100%;
    margin-bottom: 24px;
  }

  .stream-page__channel-info__profile__avatar {
    width: 60%;
  }

  .stream-page__channel-info__profile__description {
    width: 40%;
    padding: 0 39px 0 27px;
  }

  .action-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .action-buttons .button__icon {
    width: 22px !important;
  }

  .stream-page__channel-info__add-view {
    width: 130px;
    min-width: 130px;
    margin-right: 8px;
  }

  .stream-page__head h2 {
    font-size: 28px;
    margin-bottom: 24px;
  }

  .stream-page__action-buttons {
    margin: 0 -6px;
  }

  .stream-page__action-button {
    padding: 0 6px;
  }

  .stream-page__recipes {
    margin: 0 -8px;
  }

  .separator {
    width: 100%;
    height: 2px;
    background: $kittch-light-grey-color;
    margin-bottom: 24px;
  }

  .separator-add-to-calendar {
    margin-bottom: 0 !important;
  }

  .separator-bottom {
    margin-bottom: 56px;
  }
}

@media all and (max-width: 950px) {
  .stream-page__container--swiper,
  .stream-page__container--swiper {
    padding: 0 0 42px 24px;
  }

  .stream-page__container--slider,
  .stream-page__container-home--slider {
    padding: 0 0 74px 24px;
  }
}

@media all and (max-width: 768px) {
  .stream-page__container h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}

@media all and (max-width: 750px) {
  .stream-page__container--slider,
  .stream-page__container-home--slider {
    padding: 0 0 40px 0;
  }

  .stream-page__container-home {
    padding: 0 0 48px 16px;
  }

  .stream-page__container--swiper,
  .stream-page__container--swiper {
    padding: 0 0 32px 0;
  }

  .stream-page__container-top h2 {
    margin-top: 45px;
  }
}

@media all and (max-width: 600px) {
  app-save-my-spot-button {
    .addeventatc_dropdown {
      left: 0 !important;
      top: -170px !important;
      width: 210px !important;
      overflow-y: scroll;
    }
  }

  app-upcoming-streams {
    .addeventatc_dropdown {
      left: 0 !important;
    }
  }
}

@media all and (max-width: 480px) {
  .stream-page__container-home {
    padding: 0 0 48px 16px;
  }

  .stream-page__container {
    padding: 0 16px 48px 16px;
  }

  .stream-page__container--swiper,
  .stream-page__container--swiper {
    padding: 0 0 29px 0;
  }

  .stream-page__container h2 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  .stream-page__channel-info {
    padding: 19px 16px 16px 16px;
  }

  .stream-page__channel-info__profile {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .stream-page__channel-info__profile__avatar {
    width: 100%;
  }

  .stream-page__channel-info__profile__description {
    width: 100%;
    padding: 16px 0 0 0;
  }

  .stream-page__channel-info__add-view {
    width: 130px;
    min-width: 130px;
    margin-right: 16px;
  }

  .stream-page__action-buttons {
    flex-direction: column;
    margin: -8px -16px;
  }

  .stream-page__action-button {
    max-width: 100%;
    padding: 8px 16px;
  }

  .stream-page__head h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .stream-page__head span {
    letter-spacing: 0.02em;
    padding-left: 8px;
  }

  .separator-top {
    margin-bottom: 32px;
  }

  .separator-bottom {
    margin-bottom: 48px;
  }

  .stream-page__container-top h2 {
    margin-top: 35px;
  }
}
