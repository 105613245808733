@import 'variables';

.loader-wrap {
  position: relative;
}

.loader-inner {
  height: 100%;
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.loader {
  height: 70px;
  width: 70px;

  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: none;
  }
}
