.mat-menu-trigger  {
  background-color: #fff;
}

.menu-header-dropdown {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $kittch-medium-grey-color;
    box-sizing: border-box;
}

@media all and (max-width: 812px) {
  .mat-menu-content {
    display: none !important;
  }
}
