@import 'variables';

.mat-typography {
  width: calc(100% - #{$sidebarWidth});
}

@media all and (max-width: 1050px){
  .mat-typography {
    width: 100%;
  }
}
